import { postApi } from './common/apis'

export default class ArenaManagementService {

    async createArena(payload, Id) {
        var response = await postApi(`arena?account_id=${Id}`, payload)
        return response;
    }

}
