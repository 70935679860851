<template>
    <Toast />
    <form @submit.prevent="submit()">
        <div class="p-d-flex p-jc-between">
            <p class="title">Arena Management</p>
            <div>
                <!-- <Button label="Cancel" class="p-button-outlined button" @click="showSuccess" /> -->
                <Button label="Add" class="button btn" type="submit" />
            </div>
        </div>

        <div class="card">
            <h4>Add Arena</h4>
            <div class="p-grid p-fluid">
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Name</p>
                    <InputText class="input-text" type="text" v-model="v$.name.$model" placeholder="name"></InputText>
                    <p v-for="error of v$.name.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a name' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Description</p>
                    <InputText
                        class="input-text"
                        type="text"
                        v-model="v$.description.$model"
                        placeholder="description"
                    ></InputText>
                    <p v-for="error of v$.description.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a description' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Arena Code</p>
                    <InputText class="input-text" type="text" v-model="v$.arenaCode.$model" placeholder="arena code"></InputText>
                    <p v-for="error of v$.arenaCode.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a valid arena code' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">System record detail <Tooltip :description="infoValue" /></p>
                    <InputText
                        class="input-text"
                        type="text"
                        v-model="v$.systemOfRecordDetails.$model"
                        placeholder="system record detail"
                    ></InputText>
                    <p v-for="error of v$.systemOfRecordDetails.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please enter a valid system record detail' }}
                    </p>
                </div>
                <div class="p-col-12 p-md-6">
                    <p class="field-title">Status</p>
                    <Dropdown
                        class="input-text"
                        v-model="status"
                        :options="statusOptions"
                        optionLabel="name"
                        optionValue="code"
                        placeholder="Select a status"
                    />
                    <p v-for="error of v$.status.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please select an status' }}
                    </p>
                </div>

                <div class="p-col-12 p-md-6">
                    <p class="field-title">Search account</p>
                    <AutoComplete
                        class="input-text"
                        placeholder="search account name"
                        v-model="v$.accountId.$model"
                        :suggestions="filteredData"
                        @complete="searchAccount($event)"
                        :dropdown="false"
                        field="name"
                    />
                    <p v-for="error of v$.accountId.$errors" :key="error.$uid" class="error-msg">
                        {{ 'Please select an account name' }}
                    </p>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { onMounted } from '@vue/runtime-core';
import UserManagementService from '../service/Usermanagement';
import ArenaManagementService from '../service/ArenaManagementService';
import { required, minLength } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import Tooltip from '../components/Tooltip.vue';

export default {
    data() {
        return {
            name: 'Dashboards',
            description: '',
            arenaCode: '',
            systemOfRecordDetails: '',
            filteredData: null,
            accountId: null,
            accounsData: null,
            rolesData: [],
            status: null,
            statusOptions: [
                { name: 'Active', code: 0 },
                { name: 'Closed', code: 1 },
                { name: 'Suspended', code: 2 },
            ],
            infoValue: `A JSON dictionary with the name and details of the platform that contains the interaction
                          records and history supporting this arena.  For example, "platform=Zendesk"
                          and the "url=https://member-name.zendesk.com" along with any other
                          important configuration details.`,
        };
    },
    validations() {
        return {
            name: { required, minLength: minLength(1) },
            description: {},
            arenaCode: { required, minLength: minLength(1) },
            accountId: { required },
            systemOfRecordDetails: {},
            status: { required },
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    created() {
        this.userManagementService = new UserManagementService();
        this.arenaManagementService = new ArenaManagementService();
        onMounted(() => {
            this.getRoles();
            this.getAccounts();
        });
    },

    components: {
        Tooltip,
    },
    methods: {
        async getRoles() {
            var roles = await this.userManagementService.getRoles();
            this.rolesData = roles.data;
        },
        async getAccounts() {
            var accounts = await this.userManagementService.getAccounts();
            this.accounsData = accounts.data;
        },
        async postArena() {
            var payload = {
                arenaCode: this.arenaCode,
                description: this.description,
                name: this.name,
                systemOfRecordDetails: this.systemOfRecordDetails ? this.systemOfRecordDetails : null,
                status: this.status,
            };
            var postData = await this.arenaManagementService.createArena(payload, this.accountId.account_id);
            if (postData.status == 200) {
                this.$toast.add({ severity: 'success', summary: postData.data, detail: '', life: 3000 });
                (this.name = ''),
                    (this.description = ''),
                    (this.arenaCode = ''),
                    (this.accountId = null),
                    (this.systemOfRecordDetails = null);
                this.v$.$reset();
            } else {
                this.$toast.add({ severity: 'error', summary: 'Error while creating user', detail: postData.data, life: 3000 });
            }
        },
        submit() {
            this.v$.$touch();
            this.postArena();
        },
        searchAccount(event) {
            if (!event.query.trim().length) {
                this.filteredData = [...this.accounsData];
            } else {
                this.filteredData = this.accounsData.filter((country) => {
                    return country.name.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },
    },
};
</script>

<style scoped>
:deep(.btn) {
    width: 8rem !important;
}

:deep(.card) {
    margin-top: 1.5rem;
}

.subtitle {
    font-size: 1rem;
}

.error-msg {
    margin-left: 1.25rem;
    color: red;
    font-size: 1rem;
    font-weight: 400;
}
.profile-title {
    font-size: 2rem;
    font-weight: 500;
    margin-left: 1rem;
}
.input-text {
    margin-left: 1rem;
    max-width: 30rem;
}
.field-title {
    margin-left: 1rem;
    font-size: 1.2rem;
    font-weight: 400;
}
::v-deep(.p-dropdown) {
    max-width: 30rem;
}
</style>
